import React from 'react';

//change wiki page index to the FAQ page index
const handleClick = () => {
  const groupIndex = 0;
  const index = 0;
  localStorage.setItem('wikiSectionIndex', JSON.stringify({ groupIndex, index }));
};

const scrollToTop = () => {
  window.scrollTo({top: 0, behavior: 'smooth'}); // Scroll to the top of the page smoothly
}

export default function Footer() {
  return (
    <footer>
      <div className="footerContent">
        <div className="information">
          <li><a className="navbar-link" href="/wiki" onClick={handleClick}>FAQ</a></li>
          <li><a className="navbar-link" href="https://discord.gg/eUWBWh7" target="_blank">Contact us</a></li>
          <li><a className="navbar-link" onClick={scrollToTop}>Back to top</a></li>
        </div>
        <div className="copyright">
          <p>Copyright © 2019-{(new Date().getFullYear())} CommandPanels</p>
        </div>
      </div>
    </footer>
  )
}
